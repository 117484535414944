import {Injectable, Injector} from '@angular/core';
import {from, Observable, of, switchMap, tap} from 'rxjs';
import {normalizeImport} from 'utilities';
import {IAccessLevel, IDialogConfirmData, IDialogData} from '../interfaces';
import {ICategoryDialogResult} from '../interfaces/i-category-info';

@Injectable({
  providedIn: 'root'
})
export class DialogInjectorService {
  public authDialog$: Observable<boolean> | null;
  public twoFactorAuthDialog$: Observable<boolean> | null;

  constructor(private injector: Injector) {}

  /**
   * Opens authDialog when it's not already opened in UI.
   * Has side effect to remove observable from {@link authDialog$} afterClosed.
   * Dialog result is null - dialog was closed by close (X) button at top right of dialog.
   * Dialog result is undefined - route was changed and dialog was closed automatically.
   */
  public openAuthDialog(): Observable<boolean | null | undefined> {
    if (this.authDialog$) {
      return of(null);
    } else {
      const normalized = normalizeImport(import('../layout/dialogs/auth-dialog/auth-dialog.service'));
      this.authDialog$ = from(normalized).pipe(switchMap(m => this.injector.get(m.AuthDialogService).openAuthDialog()));
      return this.authDialog$.pipe(tap(() => (this.authDialog$ = null)));
    }
  }

  /**
   * Opens twoFactorAuthDialog when it's not already opened in UI.
   * Has side effect to remove observable from {@link twoFactorAuthDialog$} afterClosed.
   * Dialog result is null - dialog was closed by close (X) button at top right of dialog.
   * Dialog result is undefined - route was changed and dialog was closed automatically.
   */
  public open2FADialog(mailAddress?: string): Observable<boolean | null | undefined> {
    if (this.twoFactorAuthDialog$) {
      return of(null);
    } else {
      const normalized = normalizeImport(
        import('../layout/dialogs/two-factor-auth-dialog/two-factor-auth-dialog.service')
      );
      this.twoFactorAuthDialog$ = from(normalized).pipe(
        switchMap(m => this.injector.get(m.TwoFactorAuthDialogService).open2FADialog(mailAddress))
      );
      return this.twoFactorAuthDialog$.pipe(tap(() => (this.twoFactorAuthDialog$ = null)));
    }
  }

  public openCategoryDialog(data: IDialogData): Observable<ICategoryDialogResult> {
    const normalized = normalizeImport(import('../../shared/layout/dialogs/category-dialog/category-dialog.service'));
    return from(normalized).pipe(switchMap(m => this.injector.get(m.CategoryDialogService).openCategoryDialog(data)));
  }

  public openUpdateDialog(): Observable<boolean> {
    const normalized = normalizeImport(import('../../shared/layout/dialogs/dialog/dialog.service'));
    return from(normalized).pipe(switchMap(m => this.injector.get(m.DialogService).openUpdateDialog()));
  }

  public openCkEditorDialog(data: IDialogData): Observable<IDialogConfirmData<string>> {
    const normalized = normalizeImport(import('../layout/dialogs/ck-editor-dialog/ck-editor-dialog.service'));
    return from(normalized).pipe(switchMap(m => this.injector.get(m.CkEditorDialogService).openCkEditorDialog(data)));
  }

  public openChangeLogDialog(data: IDialogData): Observable<unknown> {
    const normalized = normalizeImport(import('../layout/dialogs/change-log-dialog/change-log-dialog.service'));
    return from(normalized).pipe(switchMap(m => this.injector.get(m.ChangeLogDialogService).openChangeLogDialog(data)));
  }

  openCorrespondenceDialog(unid?: string, name?: string): Observable<IAccessLevel> {
    const normalized = normalizeImport(
      import('../../correspondence/correspondence-dialog/correspondence-dialog.service')
    );
    return from(normalized).pipe(
      switchMap(m => this.injector.get(m.CorrespondenceDialogService).openCorrespondenceDialog(unid, name))
    );
  }
}
